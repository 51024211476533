import React from "react";
import Helmet from "react-helmet";
import Navigation from "../components/navigation"
import SEO from "../components/seo"
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/700.css";

const Layout = ({ children }) => (
<div id="myBody" className="pb-5">

	<SEO title="Mariane Y. Schneider" keywords={[`Mariane Y. Schneider`, `Mariane Schneider`, `research`, `Wastewater treatment plants`, `portfolio`]} />
	<Helmet>
		<link
		rel="stylesheet"
		href="https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
		integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS"
		crossorigin="anonymous"
		/>
		<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css"
		/>
	</Helmet>
	<Navigation />
	{ children }
</div>
);


export default Layout;
