import React from "react"

import { Container, Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"

const Navigation = () => (
<Navbar bg="white" expand="lg">
	<Container>
	<Navbar.Brand><Link to="/">Mariane Y. Schneider</Link></Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
	<Navbar.Collapse id="basic-navbar-nav">
		<Nav className="ml-auto">
			<Nav.Link as={Link} to="/publications">Publications</Nav.Link>
			<Nav.Link as={Link} to="/jasm">JASM</Nav.Link>
		</Nav>
	</Navbar.Collapse>
	</Container>
</Navbar>
);

export default Navigation;
